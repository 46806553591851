@import "~bootswatch/dist/cyborg/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/cyborg/bootswatch";

$navbar-height: 53px;

$navbar-background: theme-color('light');
.navbar {
    @extend .navbar-dark;
    background-color: $navbar-background;
}

$subform-card-color: theme-color('light');

@import '../main.scss';